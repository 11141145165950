<template>
    <div class="Container">
        <div class="conten">
            <div class="screen">
                <div class="screen_left">
                    <el-button type="primary" size="small" @click="handleAdd">添加权益类目</el-button>
                </div>
                <div class="screen_right">
                    <span>搜索权益</span>
                    <el-input @change="changeScreen" @input="changeScreen" v-model="search_value" placeholder="会员权益名称"
                        size="small" style="width: 280px;"></el-input>
                    <!-- <el-button type="text" size="small">查看筛选条件</el-button> -->
                </div>
            </div>
            <div class="table_Container" :style="{ 'height': maxHeight_TableContainer + 'px' }">
                <el-table :data="tableData" style="width: 100%" :max-height="maxHeight_Table">
                    <el-table-column prop="name" label="权益名称" align="center"></el-table-column>
                    <el-table-column prop="name" label="权益类型" align="center">
                        <template slot-scope="scope">
                            <div class="table_Container_equity">
                                <span v-if="scope.row.equity_type.includes('0')">通用权益</span>
                                <span v-if="scope.row.equity_type.includes('1')">电竞酒店</span>
                                <span v-if="scope.row.equity_type.includes('2')">商城</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="equity_id" label="权益内容" align="center"></el-table-column>
                    <el-table-column prop="equity_value" label="详细规格" align="center"></el-table-column>
                    <el-table-column prop="start_date" label="开始时间" align="center"></el-table-column>
                    <el-table-column prop="end_date" label="结束时间" align="center"></el-table-column>

                    <el-table-column prop="name" label="操作" align="right" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button type="text" style="color: #FE2F2F;"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <pagination :data="pageNation_data" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                    </pagination>
                </div>
            </div>
        </div>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="550px">
            <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item label="权益名称">
                    <el-input v-model="form.name" size="small" style="width: 150px;"></el-input>
                </el-form-item>
                <el-form-item label="权益时效">
                    <el-date-picker v-model="form.date" size="small" style="width: 280px;" value-format="yyyy-MM-dd"
                        type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="权益类型">
                    <div>
                        <el-radio v-model="form.nature" label="0">通用权益</el-radio>
                    </div>
                    <div>
                        <el-radio v-model="form.nature" label="1">指定权益
                            <div style="margin:10px 0 0 1em;">
                                <el-checkbox-group v-model="form.natures">
                                    <el-checkbox label="1" key="1" :disabled="form.nature != 1">电竞酒店</el-checkbox>
                                    <el-checkbox label="2" key="2" :disabled="form.nature != 1">商城</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-radio>
                    </div>
                </el-form-item>
                <el-form-item label="权益内容">
                    <el-select v-model="form.conten" placeholder="请选择" style="width: 150px;" size="small">
                        <el-option v-for="item in sku_options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细规格">
                    <el-input v-model="form.equity_value" size="small" style="width: 150px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
                <el-button size="small" style="width: 100px;" @click="dialogVisible = false">取消</el-button>
                <el-button size="small" style="width: 100px;" type="primary" @click="confirmEquity">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '../../../../components/PaginationPage/Pagination.vue'
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },
            search_value: null,//会员权益名称搜索值
            tableData: [],
            maxHeight_TableContainer: window.innerHeight - 180,//表格容器最大高度
            maxHeight_Table: window.innerHeight - 230,//表格最大高度

            // 对话框
            dialogTitle: '新增权益类目',//对话框标题
            dialogVisible: false,
            form: {
                id: null,
                name: '',
                date: null,
                nature: '0',
                natures: [],
                conten: '',//权益内容
                equity_value: null,//规格
            },
            formLabelWidth: '120px',
            sku_options: [],
        }
    },
    methods: {
        getType() {
            this.my_request('/admin/member/equity/type').then(res => {
                console.log('获取会员权益类别:', res);
                let { code, data } = res.data
                if (200 == code) {
                    let keys = Object.keys(data)
                    this.sku_options = []
                    keys.forEach(item => {
                        this.sku_options.push({
                            label: data[item],
                            value: item
                        })
                    })

                }
            })
        },
        getList() {
            let data = {
                name: this.search_value,
                page: this.pageNation_data.currentPage,
                page_rows: this.pageNation_data.currentSize,
            }
            console.log('获取权益列表data', data);
            this.my_request('admin/member/equity', data, 'GET').then(res => {
                console.log('获取权益列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data.list.map(item => {
                        item.equity_type = item.equity_type.split(',')
                        return item
                    })
                    this.pageNation_data.total = data.total
                }
            })
        },
        // 改变筛选值
        changeScreen() {
            console.log(this.search_value, 'search_value');
            this.getList()
        },
        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            this.getList()
        },
        // 保存权益 确认添加/编辑
        confirmEquity() {
            if (this.dialogTitle == '新增权益类目') {
                let data = {
                    name: this.form.name,
                    start_date: this.form.date[0],
                    end_date: this.form.date[1],
                    equity_type: this.form.nature == 0 ? 0 : this.form.natures.join(','),
                    equity_id: this.form.conten,
                    equity_value: this.form.equity_value,
                }
                console.log(data, '保存权益添加');
                this.my_request('admin/member/equity', data, 'POST').then(res => {
                    console.log(res, '添加权益结果');
                    let { code, message } = res.data
                    if (200 == code) {
                        this.$message.success('添加成功！')
                        this.dialogVisible = false
                        this.getList()
                    } else {
                        this.$message.error(message)
                    }
                })
            } else if (this.dialogTitle == '编辑权益类目') {
                let data = {
                    id: this.form.id,
                    name: this.form.name,
                    start_date: this.form.date[0],
                    end_date: this.form.date[1],
                    equity_type: this.form.nature == 0 ? 0 : this.form.natures.join(','),
                    equity_id: this.form.conten,
                    equity_value: this.form.equity_value,
                }
                console.log(data, '保存权益编辑');
                this.my_request('admin/member/equity', data, 'PUT').then(res => {
                    console.log(res, '编辑权益结果');
                    let { code, message } = res.data
                    if (200 == code) {
                        this.$message.success('编辑成功！')
                        this.dialogVisible = false
                        this.getList()
                    } else {
                        this.$message.error(message)
                    }
                })
            }

        },
        handleAdd() {
            this.dialogTitle = '新增权益类目'
            this.form = {
                name: '',
                date: null,
                nature: '0',
                natures: [],
                conten: '',//权益内容
                equity_value: null,//规格
            },
                this.dialogVisible = true
        },
        handleEdit(index, row) {
            console.log(index, row);
            this.dialogTitle = '编辑权益类目'
            this.form.date = [row.start_date, row.end_date]
            this.form.name = row.name
            this.form.id = row.id
            this.form.equity_value = row.equity_value
            const foundItem = this.sku_options.find(item => item.label === row.equity_id);
            if (foundItem) {
                this.form.conten = foundItem.value;
            }
            this.form.nature = row.equity_type.includes('0') ? '0' : '1'
            this.form.natures = row.equity_type.includes('0') ? [] : row.equity_type

            this.dialogVisible = true
        },
        // 点击表格删除
        handleDelete(index, row) {
            console.log(index, row);
            this.$confirm('此操作将永久删除该权益, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.my_request('admin/member/equity', data, 'DELETE').then(res => {
                    console.log(res, '删除权益结果');
                    let { code } = res.data
                    if (200 == code) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 监听窗口大小变化
        handleResize() {
            // 在这里处理窗口大小变化的逻辑
            let screenHeight = window.innerHeight;
            this.maxHeight_TableContainer = screenHeight - 180
            this.maxHeight_Table = screenHeight - 230
        },
    },
    created() {
        this.getList()
        this.getType()
    },
    mounted() {
        // 监听窗口大小变化
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        // 移除窗口大小变化的监听
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style lang="scss" scoped>
.table_Container_equity {
    span {
        margin-left: 10px;
    }
}

.el-form-item {
    margin-bottom: 10px;
}

.Container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .conten {
        .screen {
            margin-bottom: 20px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .screen_right {
                display: flex;
                align-items: center;
                font-size: 12px;

                span {
                    margin-right: 10px;
                }

                .el-button {
                    margin: 0 10px;
                }
            }
        }

        .table_Container {
            position: relative;
            // background-color: antiquewhite;

            .pagination {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}
</style>